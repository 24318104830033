<template>
  <div class="">
    ahmet
  </div>
</template>

<script>
  export default {
    name: 'Kurulum',
  }
</script>

<style></style>
